
import { defineComponent, onBeforeMount } from 'vue'

// module components
import ComplaintsFilters from '@/modules/PretensionsModule/components/ComplaintsFilters/index.vue'
import ComplaintsList from '@/modules/PretensionsModule/components/ComplaintsList/index.vue'

// composable
import useComplaints from '@/modules/PretensionsModule/composable/useComplaints'
import { ComplaintsGetListParams } from '@/core/new-services/types/complaints'

export default defineComponent({
    components: {
        ComplaintsFilters,
        ComplaintsList,
    },
    setup() {
        const { state, getList } = useComplaints()

        const applyFilters = async (params: ComplaintsGetListParams) => {
            await getList(1, params)
        }

        onBeforeMount(getList)

        return {
            state,
            //
            applyFilters,
            getList,
        }
    },
})
