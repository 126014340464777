import { AxiosResponse } from 'axios'
import { http } from '@/core/classes/HTTP.class'

import {
    ComplaintsGetComplaintResponse,
    ComplaintsGetListParams,
    ComplaintsGetListResponse,
} from '@/core/new-services/types/complaints'

type ApiGetListResponse = Promise<AxiosResponse<ComplaintsGetListResponse>>
type ApiGetComplaintResponse = Promise<AxiosResponse<ComplaintsGetComplaintResponse>>
type ApiCreateComplaintResponse = Promise<AxiosResponse<void>>

export function getList(params: ComplaintsGetListParams): ApiGetListResponse {
    return http.get('/admin/complaint', { params })
}

export function createComplaint(form_data: FormData): ApiCreateComplaintResponse {
    return http.post('/admin/complaint', form_data)
}

export function getComplaint(id: number): ApiGetComplaintResponse {
    return http.get(`/admin/complaint/${id}`)
}

export default {
    getList,
    createComplaint,
    getComplaint,
}
