import { AxiosResponse } from 'axios'
import { http } from '@/core/classes/HTTP.class'

import {
    PretensionsGetByIdResponseT,
    PretensionsGetListResponseT,
    PretensionsGetScanResponseT, PretensionsGetStatusesResponseT,
    PretensionsGetSubdivisionsResponseT,
    PretensionsGetTypesResponseT,
} from '@/core/types/Responses/Pretensions.responses'
import { PretensionListParamsT, PretensionPayloadT } from '../types/Pretensions.types'

export class PretensionsRepository {
    getList(params: PretensionListParamsT): Promise<AxiosResponse<PretensionsGetListResponseT>> {
        return http.get('/pretensions', { params: { ...params, is_client: 1 } })
    }

    deletePretension(pretension_id: number): Promise<AxiosResponse<void>> {
        return http.delete(`/pretensions/${pretension_id}`)
    }

    createPretension(form_data: FormData): Promise<AxiosResponse<void>> {
        return http.postFormData('/pretensions', form_data)
    }

    updatePretension(
        pretension_id: number,
        payload: PretensionPayloadT
    ): Promise<AxiosResponse<void>> {
        return http.put(`/pretensions/${pretension_id}`, payload)
    }

    uploadFiles(params: FormData): Promise<AxiosResponse<void>> {
        return http.post('/pretensions/save-files', params)
    }

    getPretension(pretension_id: number): Promise<AxiosResponse<PretensionsGetByIdResponseT>> {
        return http.get(`/pretensions/${pretension_id}`)
    }

    getInvoiceScan(invoice_number: string): Promise<AxiosResponse<PretensionsGetScanResponseT>> {
        return http.get(`/v2/logistics-info/invoice-number/${invoice_number}`)
    }

    getTypes(): Promise<AxiosResponse<PretensionsGetTypesResponseT>> {
        return http.get('/pretension-types')
    }

    getStatuses(): Promise<AxiosResponse<PretensionsGetStatusesResponseT>> {
        return http.get('/pretension-statuses')
    }

    deleteFile(file_id: number): Promise<AxiosResponse<void>> {
        return http.delete(`/file/${file_id}`)
    }

    getSubdivisions(params: any): Promise<AxiosResponse<PretensionsGetSubdivisionsResponseT>> {
        return http.get('/v2/companies/subdivisions', { params })
    }
}
