import { reactive } from 'vue'

// constants
import { COMPLAINTS_PARAMS } from '@/core/constants/Pretensions.constants'

// services
import complaintsAPI from '@/core/new-services/complaints.service'

// types
import { Complaint, ComplaintsGetListParams } from '@/core/new-services/types/complaints'

export default function () {
    const state = reactive({
        is_loading: false,
        is_failed: false,
        //
        pagination: {
            current_page: 1,
            last_page: 1,
        },
        //
        list: [] as Complaint[],
    })

    //

    const getList = async (page = 1, params: ComplaintsGetListParams = COMPLAINTS_PARAMS) => {
        state.pagination.current_page = page

        try {
            state.is_loading = true

            const response = await complaintsAPI.getList({ ...params, page })

            state.list = response.data.data
            state.pagination.last_page = response.data.meta.last_page

            state.is_failed = false
        } catch (error) {
            console.error(error)
            state.is_failed = true
        } finally {
            state.is_loading = false
        }
    }

    return {
        state,
        //
        getList,
    }
}
