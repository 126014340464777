import { PretensionsRepository } from '../repositories/Pretensions.repository'
import { PretensionListParamsT, PretensionPayloadT } from '../types/Pretensions.types'
import {
    PretensionsGetByIdResponseT,
    PretensionsGetListResponseT,
    PretensionsGetScanResponseT,
    PretensionsGetStatusesResponseT,
    PretensionsGetSubdivisionsResponseT,
    PretensionsGetTypesResponseT,
} from '../types/Responses/Pretensions.responses'

const pretensionsRepository = new PretensionsRepository()

const pretensionsService = {
    async getList(params: PretensionListParamsT): Promise<PretensionsGetListResponseT> {
        const response = await pretensionsRepository.getList(params)
        return response.data
    },

    async deletePretension(pretension_id: number): Promise<void> {
        const response = await pretensionsRepository.deletePretension(pretension_id)
        return response.data
    },

    async createPretension(form_data: FormData): Promise<void> {
        const response = await pretensionsRepository.createPretension(form_data)
        return response.data
    },

    async updatePretension(pretension_id: number, payload: PretensionPayloadT): Promise<void> {
        const response = await pretensionsRepository.updatePretension(pretension_id, payload)
        return response.data
    },

    async uploadFiles(params: FormData): Promise<void> {
        const response = await pretensionsRepository.uploadFiles(params)
        return response.data
    },

    async getPretension(pretension_id: number): Promise<PretensionsGetByIdResponseT> {
        const response = await pretensionsRepository.getPretension(pretension_id)
        return response.data
    },

    async getInvoiceScan(invoice_number: string): Promise<PretensionsGetScanResponseT> {
        const response = await pretensionsRepository.getInvoiceScan(invoice_number)
        return response.data
    },

    async getTypes(): Promise<PretensionsGetTypesResponseT> {
        const response = await pretensionsRepository.getTypes()
        return response.data
    },

    async getStatuses(): Promise<PretensionsGetStatusesResponseT> {
        const response = await pretensionsRepository.getStatuses()
        return response.data
    },

    async deleteFile(file_id: number): Promise<void> {
        const response = await pretensionsRepository.deleteFile(file_id)
        return response.data
    },

    async getSubdivisions(params: any): Promise<PretensionsGetSubdivisionsResponseT> {
        const response = await pretensionsRepository.getSubdivisions(params)
        return response.data
    },
}

export default pretensionsService
