
import { defineComponent, PropType } from 'vue'

// Components
import SLoader from '@/common/components/SLoader/index.vue'
import SButton from '@/common/components/SButton/index.vue'
import SPagination from '@/common/components/SPagination/index.vue'

// Types
import { PretensionT } from '@/core/types/Pretensions.types'

export default defineComponent({
    components: {
        's-loader': SLoader,
        's-button': SButton,
        's-pagination': SPagination,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        failed: {
            type: Boolean,
            default: false,
        },
        pretensions: {
            type: Array as PropType<PretensionT[]>,
            default: () => [],
        },
        currentPage: {
            type: Number,
            default: 1,
        },
        totalPages: {
            type: Number,
            default: 1,
        },
    },
    emits: ['refresh', 'remove', 'change-page'],
    setup(props, { emit }) {
        const emitRefresh = () => {
            emit('refresh')
        }

        const emitRemove = (id: number) => {
            emit('remove', id)
        }

        const emitChangePage = (page: number) => {
            emit('change-page', page)
        }

        return { emitRefresh, emitRemove, emitChangePage }
    },
})
