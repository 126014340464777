export const PRETENSIONS_VALUE = 'PRETENSIONS'
export const COMPLAINTS_VALUE = 'COMPLAINTS'

export const PRETENSIONS = { id: 1, name: 'Претензии', value: PRETENSIONS_VALUE }
export const COMPLAINTS = { id: 2, name: 'Жалобы', value: COMPLAINTS_VALUE }

export const PRETENSIONS_TABS = [PRETENSIONS, COMPLAINTS]

export const COMPLAINTS_PARAMS = {
    page: 1,
    limit: 20,
    invoiceNumber: null,
    id: null,
    fromDate: null,
    toDate: null,
    statusId: null,
}

export const COMPLAINT_STATUSES = [
    { id: 43, name: 'Создан', value: 43 },
    { id: 44, name: 'Согласован', value: 44 },
    { id: 45, name: 'Закрыт', value: 45 },
]
