
import { defineComponent, PropType } from 'vue'

// Components
import SLoader from '@/common/components/SLoader/index.vue'
import SButton from '@/common/components/SButton/index.vue'
import SPagination from '@/common/components/SPagination/index.vue'

// Types
import { Complaint } from '@/core/new-services/types/complaints'

export default defineComponent({
    components: {
        's-loader': SLoader,
        's-button': SButton,
        's-pagination': SPagination,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        failed: {
            type: Boolean,
            default: false,
        },
        complaints: {
            type: Array as PropType<Complaint[]>,
            default: () => [],
        },
        currentPage: {
            type: Number,
            default: 1,
        },
        totalPages: {
            type: Number,
            default: 1,
        },
    },
    emits: ['refresh', 'change-page'],
    setup(_, { emit }) {
        const emitRefresh = () => {
            emit('refresh')
        }

        const emitChangePage = (page: number) => {
            emit('change-page', page)
        }

        return { emitRefresh, emitChangePage }
    },
})
