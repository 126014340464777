
import { defineComponent, PropType, reactive } from 'vue'

// components
import SInput from '@/common/components/SInput/index.vue'
import SButton from '@/common/components/SButton/index.vue'

// types
import { OptionT } from '@/core/types/common.types'

// constants
import { COMPLAINT_STATUSES } from '@/core/constants/Pretensions.constants'

export default defineComponent({
    components: {
        's-input': SInput,
        's-button': SButton,
    },
    emits: ['search', 'reset'],
    setup(_, { emit }) {
        const state = reactive({
            invoiceNumber: null as string | null,
            id: null as string | null,
            fromDate: null as string | null,
            toDate: null as string | null,
            statusId: null as OptionT<string> | null,
        })

        //

        const search = () => {
            emit('search', { ...state })
        }

        const reset = () => {
            state.invoiceNumber = null
            state.id = null
            state.fromDate = null
            state.toDate = null
            state.statusId = null

            emit('reset')
        }

        //

        return {
            state,
            //
            search,
            reset,
            //
            COMPLAINT_STATUSES,
        }
    },
})
