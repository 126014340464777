import { computed, reactive } from 'vue'

import { PretensionStatusT, PretensionT, PretensionTypeT } from '@/core/types/Pretensions.types'
import { PretensionsRealisation } from '@/core/realisations/Pretensions.realisation'
import {
    COMPLAINTS_VALUE,
    PRETENSIONS,
    PRETENSIONS_VALUE,
} from '@/core/constants/Pretensions.constants'
import { OptionT } from '@/core/types/common.types'

export default function () {
    const pretensions = new PretensionsRealisation()

    const state = reactive({
        is_loading: false,
        is_failed: false,
        //
        is_delete: false,
        delete_id: null as number | null,
        is_delete_loading: false,
        //
        pagination: {
            current_page: 1,
            last_page: 1,
        },
        //
        list: [] as PretensionT[],
        types: [] as PretensionTypeT[],
        statuses: [] as PretensionStatusT[],
        //
        current_tab: PRETENSIONS,
    })

    //

    const getList = async (page: number, params?: any) => {
        state.pagination.current_page = page

        try {
            state.is_loading = true

            const response = await pretensions.getList({
                page: state.pagination.current_page,
                limit: 20,
                ...params,
            })

            state.list = response.data
            state.pagination.last_page = response.meta.last_page

            state.is_failed = false
        } catch (error) {
            console.error(error)
            state.is_failed = true
        } finally {
            state.is_loading = false
        }
    }

    const getTypes = async () => {
        try {
            state.is_loading = true

            const response = await pretensions.getTypes()

            state.types = response.data.map((option) => {
                return { ...option, value: option.id }
            })
            state.is_failed = false
        } catch (error) {
            console.error(error)
            state.is_failed = true
        } finally {
            state.is_loading = false
        }
    }

    const getStatuses = async () => {
        try {
            state.is_loading = true

            const response = await pretensions.getStatuses()

            state.statuses = response.data.map((option) => {
                return { ...option, value: option.code }
            })
            state.is_failed = false
        } catch (error) {
            console.error(error)
            state.is_failed = true
        } finally {
            state.is_loading = false
        }
    }

    const startDelete = (id: number) => {
        state.is_delete = true
        state.delete_id = id
    }

    const stopDelete = () => {
        state.is_delete = false
        state.delete_id = null
    }

    const deletePretension = async () => {
        if (!state.delete_id) return

        try {
            state.is_delete_loading = true
            await pretensions.deletePretension(state.delete_id)

            getList(state.pagination.current_page)
            stopDelete()
        } catch (error) {
            console.error(error)
        } finally {
            state.is_delete_loading = false
        }
    }

    const setCurrentTab = (option: OptionT<string>) => {
        state.current_tab = option
    }

    //

    const isPretensions = computed(() => state.current_tab.value === PRETENSIONS_VALUE)
    const isComplaints = computed(() => state.current_tab.value === COMPLAINTS_VALUE)

    //

    return {
        state,
        //
        getList,
        startDelete,
        stopDelete,
        deletePretension,
        getTypes,
        getStatuses,
        setCurrentTab,
        //
        isPretensions,
        isComplaints,
    }
}
