
import { computed, defineComponent } from 'vue'

// components
import SButton from '@/common/components/SButton/index.vue'
import SSwitch from '@/common/components/SSwitch/index.vue'

// constants
import { PRETENSIONS_TABS } from '@/core/constants/Pretensions.constants'

// module components
import PretensionsPage from '@/modules/PretensionsModule/components/PretensionsPage/index.vue'
import ComplaintsPage from '@/modules/PretensionsModule/components/ComplaintsPage/index.vue'

// composable
import usePretensions from '@/modules/PretensionsModule/composable/usePretensions'
import { useTitle } from 'vue-composable'

export default defineComponent({
    components: {
        's-button': SButton,
        's-switch': SSwitch,
        PretensionsPage,
        ComplaintsPage,
    },
    setup() {
        const { state, setCurrentTab, isPretensions, isComplaints } = usePretensions()
        useTitle(isPretensions.value ? 'Список претензий' : 'Список жалоб')

        const title = computed(() => (isPretensions.value ? 'Претензии' : 'Жалобы'))
        const btnText = computed(() =>
            isPretensions.value ? 'Создать претензию' : 'Создать жалобу'
        )
        const btnUrl = computed(() =>
            isPretensions.value ? '/pretensions/create' : '/complaints/create'
        )

        return {
            state,
            //
            setCurrentTab,
            //
            isPretensions,
            isComplaints,
            title,
            btnText,
            btnUrl,
            //
            PRETENSIONS_TABS,
        }
    },
})
