
import { defineComponent, PropType, reactive } from 'vue'

// components
import SInput from '@/common/components/SInput/index.vue'
import SButton from '@/common/components/SButton/index.vue'

// types
import { PretensionStatusT, PretensionTypeT } from '@/core/types/Pretensions.types'
import { OptionT } from '@/core/types/common.types'

export default defineComponent({
    components: {
        's-input': SInput,
        's-button': SButton,
    },
    props: {
        types: {
            type: Array as PropType<PretensionTypeT[]>,
            default: () => [],
        },
        statuses: {
            type: Array as PropType<PretensionStatusT[]>,
            default: () => [],
        },
    },
    emits: ['search', 'reset'],
    setup(_, { emit }) {
        const state = reactive({
            invoice_number: null as string | null,
            client_name: null as string | null,
            product_description: null as string | null,
            type_id: null as OptionT<number> | null,
            status_code: null as OptionT<string> | null,
        })

        const search = () => {
            emit('search', { ...state })
        }

        const reset = () => {
            state.invoice_number = null
            state.client_name = null
            state.product_description = null
            state.type_id = null
            state.status_code = null

            emit('reset')
        }

        return { state, search, reset }
    },
})
