
import { defineComponent, onBeforeMount } from 'vue'

// components
import SModal from '@/common/components/SModal/index.vue'

// module components
import PretensionsFilters from '@/modules/PretensionsModule/components/PretensionsFilters/index.vue'
import PretensionsList from '@/modules/PretensionsModule/components/PretensionsList/index.vue'
import PretensionDelete from '@/modules/PretensionsModule/components/PretensionDelete/index.vue'

// composable
import usePretensions from '@/modules/PretensionsModule/composable/usePretensions'

export default defineComponent({
    components: {
        's-modal': SModal,
        PretensionsFilters,
        PretensionsList,
        PretensionDelete,
    },
    setup() {
        const { state, getList, getTypes, getStatuses, startDelete, stopDelete, deletePretension } =
            usePretensions()

        const applyFilters = async (params: any) => {
            await getList(1, params)
        }

        onBeforeMount(async () => {
            await getTypes()
            await getStatuses()
            await getList(1)
        })

        return {
            state,
            //
            applyFilters,
            startDelete,
            stopDelete,
            deletePretension,
            getList,
        }
    },
})
