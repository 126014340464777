import { PretensionsI } from '../interfaces/Pretensions.interface'
import {
    PretensionsGetByIdResponseT,
    PretensionsGetListResponseT,
    PretensionsGetScanResponseT,
    PretensionsGetStatusesResponseT,
    PretensionsGetSubdivisionsResponseT,
    PretensionsGetTypesResponseT,
} from '../types/Responses/Pretensions.responses'

import pretensionsService from '../services/Pretensions.service'
import { PretensionListParamsT, PretensionPayloadT } from '../types/Pretensions.types'

export class PretensionsRealisation implements PretensionsI {
    async getList(params: PretensionListParamsT): Promise<PretensionsGetListResponseT> {
        return await pretensionsService.getList(params)
    }

    async deletePretension(pretension_id: number): Promise<void> {
        return await pretensionsService.deletePretension(pretension_id)
    }

    async createPretension(form_data: FormData): Promise<void> {
        return await pretensionsService.createPretension(form_data)
    }

    async updatePretension(pretension_id: number, payload: PretensionPayloadT): Promise<void> {
        return await pretensionsService.updatePretension(pretension_id, payload)
    }

    uploadFiles(params: FormData): Promise<void> {
        return pretensionsService.uploadFiles(params)
    }

    async getPretension(pretension_id: number): Promise<PretensionsGetByIdResponseT> {
        return await pretensionsService.getPretension(pretension_id)
    }

    async getInvoiceScan(invoice_number: string): Promise<PretensionsGetScanResponseT> {
        return await pretensionsService.getInvoiceScan(invoice_number)
    }

    async getTypes(): Promise<PretensionsGetTypesResponseT> {
        return await pretensionsService.getTypes()
    }

    getStatuses(): Promise<PretensionsGetStatusesResponseT> {
        return pretensionsService.getStatuses()
    }

    async deleteFile(file_id: number): Promise<void> {
        return await pretensionsService.deleteFile(file_id)
    }

    getSubdivisions(params: any): Promise<PretensionsGetSubdivisionsResponseT> {
        return pretensionsService.getSubdivisions(params)
    }
}
