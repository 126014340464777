
import {
    defineComponent,
    onBeforeUnmount,
    onBeforeUpdate,
    onMounted,
    PropType,
    ref,
    Ref,
} from 'vue'

import { OptionT } from '@/core/types/common.types'

export default defineComponent({
    props: {
        value: {
            type: Object as PropType<OptionT<Record<string, unknown>>>,
            required: true,
        },
        options: {
            type: Array as PropType<OptionT<Record<string, unknown>>[]>,
            required: true,
        },
        theme: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:value', 'change'],
    setup(props, { emit }) {
        const thumbler: Ref<HTMLElement | null> = ref(null)
        const selectedValueIndex: Ref<number> = ref(0)

        let refs: HTMLElement[] = []

        const pushRef = (element: HTMLElement | null) => {
            if (element) {
                refs.push(element)
            }
        }

        onBeforeUpdate(() => {
            refs = []
        })

        const moveThumbler = (valueIndex: number) => {
            const selectedValueRef: HTMLElement = refs[valueIndex]

            const newThumblerTranslateX = `translateX(${selectedValueRef.offsetLeft}px)`
            const newThumblerWidth = `${selectedValueRef.offsetWidth}px`

            thumbler.value?.style.setProperty('transform', newThumblerTranslateX)
            thumbler.value?.style.setProperty('width', newThumblerWidth)

            selectedValueIndex.value = valueIndex
        }

        const changeValue = (valueIndex: number): void => {
            if (props.disabled) return

            const selectedValue: OptionT<Record<string, unknown>> = props.options[valueIndex]
            moveThumbler(valueIndex)

            emit('update:value', selectedValue)
            emit('change', selectedValue)
        }

        const findValueIndex = (): number => {
            const valueIndex = props.options.findIndex(
                (propValue) => propValue.name === props.value.name
            )

            if (valueIndex < 0) return 0
            return valueIndex
        }

        const initializeSwitch = () => {
            const initialValueIndex = findValueIndex()
            moveThumbler(initialValueIndex)

            window.addEventListener('resize', () => moveThumbler(selectedValueIndex.value))
        }

        onMounted(initializeSwitch)
        onBeforeUnmount(() =>
            window.addEventListener('resize', () => moveThumbler(selectedValueIndex.value))
        )

        // watch(
        //     () => props.value,
        //     (currentValue, oldValue) => {
        //         if (currentValue === oldValue) return
        //         const findedIndex = findValueIndex()
        //         moveThumbler(findedIndex)
        //     }
        // )

        return {
            //
            thumbler,
            pushRef,
            changeValue,
            selectedValueIndex,
        }
    },
})
